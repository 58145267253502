import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modern-and-applicative-javascript---part-1"
    }}>{`Modern and applicative JavaScript - Part 1`}</h1>
    <h1 {...{
      "id": "building-rest-api-with-expressjs"
    }}>{`Building REST API with express.js`}</h1>
    <h5 {...{
      "id": "july-12-2019--aptitude-softwares-office"
    }}>{`July 12, 2019 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/building-rest-api-expressjs"
        }}>{`https://slides.com/kajetansw/building-rest-api-expressjs`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~90 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: workshops`}</li>
    </ul>
    <hr></hr>
    <p>{`After a really broad introduction to JavaScript as a language with a lot of theory and facts I decided that it was a time to jump into practice!`}</p>
    <p>{`The goal was to build foundations for a modern web application using various JavaScript technologies. The theme was to create a "Splitter" application. It is a  clone of `}<a parentName="p" {...{
        "href": "https://www.splitwise.com/"
      }}>{`Splitwiseⓒ`}</a>{` - application for splitting expenses with other users.`}</p>
    <p>{`During these workshops all participants had an opportunity to learn modern ES2015+ standards of JavaScript by building REST API with NodeJS and `}<a parentName="p" {...{
        "href": "https://expressjs.com/"
      }}>{`express.js`}</a>{`. `}</p>
    <hr></hr>
    <h3 {...{
      "id": "outline"
    }}>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`What is so "modern" and "applicative" about JavaScript`}</li>
      <li parentName="ul">{`What will be our technology stack?`}</li>
      <li parentName="ul">{`What are the coolest ES2015+ features and why they are cool?`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Array.prototype`}</inlineCode>{` functions`}</li>
      <li parentName="ul">{`Introducting our project`}</li>
      <li parentName="ul">{`What is express.js`}</li>
      <li parentName="ul">{`👨‍💻 CODING TIME 👨‍💻`}</li>
      <li parentName="ul">{`What's next? `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      